.button {
  padding: 1rem;
  border-radius: 8px;
  font-family: Satoshi;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-transform: capitalize;
  cursor: pointer;
}

.primary {
  border: 1px solid #fceb4f;
  color: #fceb4f;
  background: #444122;
}

.secondary {
  border: 1px solid #fceb4f;
  color: #fceb4f;
  background: transparent;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .button {
    font-size: 14px;
    line-height: 23px;
    padding: 8px 10px;
  }
}

@media screen and (max-width: 767px) {
  .button {
    font-size: 18px;
    line-height: 23px;
    padding: 8px 16px;
  }
}
