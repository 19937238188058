.container {
  position: relative;
}

.visit {
  position: fixed;
  bottom: 60px;
  right: 30px;
  z-index: 20;
  height: 44px;
  width: 44px;

  background: #fadc4e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
}

.visit > div {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #0f0c13;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.7s ease-in-out;
  transition-delay: 0.5s;
  white-space: nowrap;
  width: 0%;
  overflow: hidden;
}

.visit > div > a {
  color: #0f0c13;
  text-decoration: none;
}

.visit2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visit > svg,
.visit2 > svg {
  width: 26px;
  height: 25px;
  transform: rotate(90deg);
}

.visit2 > svg {
  width: 18px;
  height: 18px;
  transform: rotate(90deg);
}

.visit:hover {
  width: 210px;
}

.visit:hover > div {
  transition-delay: 0.5s;
  transition: all 0.9s ease-in-out;
  display: flex;
  width: 95%;
}

.visit:hover > svg {
  display: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .visit {
    height: 30px;
    width: 30px;
  }

  .visit > div {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .visit {
    height: 30px;
    width: 30px;
  }

  .visit > div {
    font-size: 14px;
    line-height: 20px;
  }
}
