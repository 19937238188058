.container {
  display: flex;
  align-items: stretch;
  height: 100vh;
}

.leftSection {
  width: 74px;
  color: var(--Main-Color, #fadc4e);
  font-family: Satoshi;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.leftSection > p {
  white-space: nowrap;
  transform: rotate(-90deg);
  position: absolute;
  top: 272px;
  left: 0;
  right: 0;
  margin: auto;
}

.leftSection > span {
  width: 44px;
  height: 44px;
  background: #fadc4e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;

  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: auto;
}

.back {
  width: 44px;
  height: 44px;
  background: #fadc4e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;

  position: fixed;
  bottom: 60px;
  left: 30px;
  z-index: 20;
  display: none;
}

.leftSection > span > svg,
.back > svg {
  transition: all 0.1s ease-in-out;
  width: 26px;
  height: 25px;
}

.rightSection {
  flex: 1;
  background: #fff;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    display: flex;
  }

  .leftSection {
    display: none;
  }

  .back {
    width: 34px;
    height: 34px;
    display: flex;
  }

  .back > svg {
    transition: all 0.1s ease-in-out;
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    display: flex;
  }

  .leftSection {
    display: none;
  }

  .back {
    width: 34px;
    height: 34px;
    display: flex;
  }

  .back > svg {
    transition: all 0.1s ease-in-out;
    width: 18px;
    height: 18px;
  }
}
