.container {
  height: 86px;
  display: flex;
  align-items: center;
  gap: 72px;
  padding: 1rem 70px;
  position: relative;
  background-color: #000;
}

.container > img {
  height: 86px;
  width: 86px;
  cursor: pointer;
}

.container > a:nth-child(2) {
  margin-left: auto;
}

.container > a {
  text-align: center;
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.container > svg {
  display: none;
  margin-left: auto;
}

.sideNav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  background-color: var(--white);
  overflow: hidden;
  width: 0%;
  transition: 0.3s;
}

.active {
  color: #fadc4e;
}

.inactive {
  color: #fff;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    height: 70px;
  }

  .container > img {
    height: 70px;
    width: 70px;
  }

  .container > a {
    display: none;
  }

  .container > svg {
    display: block;
  }

  .buttonSection {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    height: 70px;
  }

  .container > img {
    height: 70px;
    width: 70px;
  }

  .container > a {
    display: none;
  }

  .container > svg {
    display: block;
  }
  .buttonSection {
    display: none;
  }
}
