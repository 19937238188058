.container {
  padding: 40px 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  height: calc(100vh - 86px);
}

.container > div {
  flex-basis: 31%;
  width: 31%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 32px 16px;
    gap: 3rem;
    min-height: calc(100vh - 86px);
    align-items: center;
    justify-content: center;
  }

  .container > div {
    flex-basis: 40%;
    width: 40%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 32px 16px;
    gap: 3rem;
    min-height: calc(100vh - 86px);
    align-items: center;
  }

  .container > div {
    flex-basis: 100%;
    width: 100%;
  }
}
