.container {
  padding: 40px 100px;
  display: flex;
  align-items: center;
  gap: 3rem;
  height: calc(100vh - 86px);
}

.leftSection {
  width: 50%;
}

.leftSection > h4 {
  color: #fff;
  font-family: Satoshi;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 144.5%;
  margin-bottom: 2rem;
}

.leftSection > h4 > span {
  color: #faea50;
}

.imageSection {
  position: relative;
  width: 50%;
  height: 100%;
}

.imageSection > div:last-child > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.imageSection > div:not(.imageSection > div:last-child) {
  position: absolute;
}

.imageSection > div:nth-child(1) {
  width: 100%;
  bottom: 0;
  height: 70px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.imageSection > div:nth-child(2) {
  width: 50px;
  height: 100%;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  right: 0;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 32px 16px;
    gap: 3rem;
    min-height: calc(100vh - 86px);
    flex-direction: column;
    background: url("https://res.cloudinary.com/dmpdhnjqs/image/upload/v1703779700/IMG_2084_f1xr3u.jpg");
    background-position: center center;
    background-size: cover;
  }

  .leftSection {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .leftSection > h4 {
    color: #fff;
    font-family: Satoshi;
    font-size: 64px;
    font-weight: 900;
    line-height: 144.5%;
    margin-bottom: 2rem;
  }

  .leftSection > h4 > span {
    color: #faea50;
  }

  .imageSection {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 32px 16px;
    gap: 3rem;
    min-height: calc(100vh - 86px);
    flex-direction: column;
    background: url("https://res.cloudinary.com/dmpdhnjqs/image/upload/v1703779700/IMG_2084_f1xr3u.jpg");
    background-position: center center;
    background-size: cover;
    height: 100%;
  }

  .leftSection {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .leftSection > h4 {
    color: #fff;
    font-family: Satoshi;
    font-size: 55px;
    font-weight: 900;
    line-height: 144.5%;
    margin-bottom: 2rem;
  }

  .leftSection > h4 > span {
    color: #faea50;
  }

  .imageSection {
    display: none;
  }
}
