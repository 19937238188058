.container {
  height: 100%;
  width: 100%;
}

.container > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
