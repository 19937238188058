.container {
  min-height: 100vh;
  position: relative;
}

.header {
  height: 86px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.body {
  height: calc(100vh - 86px);
  overflow-y: auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    height: 70px;
  }

  .body {
    height: calc(100vh - 76px);
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 70px;
  }

  .body {
    height: calc(100vh - 76px);
  }
}
